

const chatUtils = () => {

    const handlePositionScroll = containerClass => {

        let element = getElementInDom(containerClass);
        element.scrollTo(0, element.scrollHeight);
    }

    const checkScrollAction = containerClass => {

        let element = getElementInDom(containerClass),
            position = element.scrollTop,
            currentHeight = element.scrollHeight;
    
        if(position > (currentHeight - 800))
            handlePositionScroll(containerClass);

    }

    const getElementInDom = containerClass =>{
        return document.getElementsByClassName(containerClass)[0];
    }

    return {
        handlePositionScroll: handlePositionScroll,
        checkScrollAction: checkScrollAction
    }

}

export default chatUtils();