import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

class RegisterSurface extends Component {
  constructor(props) {
    super(props);

    let { match } = props;

    this.gameType = match.params.gameType;
    this.regButton = React.createRef();

    this.state = {
      company: "",
      userName: "",
      userExists: true
    };
  }

  onChangeCompany = e => {
    this.setState({
      company: e.target.value
    });
  };

  onChangeUserName = e => {
    this.setState({
      userName: e.target.value
    });
  };

  checkAvailableUserName = async () => {
    const userName = this.state.userName.trim();
    const room = this.state.company.trim();
    const gameType = this.gameType;

    const response = await fetch("/api/checkuser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ userName, room, gameType })
    });

    const { userExists, drawNumber } = await response.json();

    if (!drawNumber) {
      this.notify("Ny omgång är på gång. Försök igen imorn!");
    } else {
      if (userExists)
        this.notify(
          "Användarnamnet du skrivit in är upptaget. Försök med ett annat!"
        );

      this.setState({ userExists });
    }
  };

  onKeyUp = e => {
    if (
      e.key === "Enter" &&
      this.state.company.length &&
      this.state.userName.length
    ) {
      this.regButton.current.click();
    }
  };

  notify = msg => {
    toast.info(msg, {
      position: toast.POSITION.TOP_CENTER
    });

    // toast("Custom Style Notification with css class!", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     className: 'foo-bar'
    // });
  };

  render() {
    if (this.gameType !== "stryk" && this.gameType !== "euro")
      return <Redirect to="/" />;

    return (
      <div className="page-wrapper">
        <div className="beta">
          <span>Skapa ett nytt bolag eller gå med ett befintligt.</span>
        </div>

        <div className="form-container">
          <div className="form">
            <input
              placeholder="bolag"
              onKeyUp={this.onKeyUp}
              onChange={this.onChangeCompany}
              className="form-input"
            />
            <input
              placeholder="namn"
              onKeyUp={this.onKeyUp}
              onChange={this.onChangeUserName}
              className="form-input"
            />

            <div className="btn-container">
              {this.state.userExists ? (
                <button
                  ref={this.regButton}
                  className="btn btn-success"
                  onClick={this.checkAvailableUserName}
                >
                  Börja tippa!
                </button>
              ) : (
                <Redirect
                  to={{
                    pathname: `/${this.props.match.params.gameType}/${this.state.userName}/${this.state.company}`
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterSurface;
