import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';

import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './components/appRoutes';

class App extends Component {


  render() {

    return (
      <div style={{ height: '100%' }}>
        <AppRoutes />
        <ToastContainer />
      </div>
      
    );
  }
}




export default App;
