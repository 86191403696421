import React from 'react';

const cost = (props) => {
    
    let cost = 1, counter = 0;
    

    for(let i = 1; i <= props.squares.length; i++)
    {
        if(props.squares[i-1].color)
            counter++;

        if(i % 3 === 0)
        {
            if(counter === 0)
            {
                cost = 0;
                break;
            }

            cost *= counter;
            counter = 0;
        }
    }
        
    return (
        <span className="cost">{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK', minimumFractionDigits: 0}).format(cost)}</span>
    )
};

export default cost;