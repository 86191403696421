import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import {isMobile} from 'react-device-detect';

import chatUtils from '../chatUtils';

import ChatSurface from './chatSurface';
import MainSurface from './mainSurface';
import UsersSurface from './usersSurface';
import Cost from './cost';

import { setSocket, subscribeToNewGameDataAvailable, addRoom, disconnect } from '../socketservices';

class GameContainer extends Component {
    constructor(props){
        super(props);

        let params = props.match.params;

        this.userData = {
            room: params.company.trim(),
            userName: params.userName.trim(),
            gameType: params.gameType.trim()
        }

        setSocket(this.userData);

        this.state = {
            users: [],
            squares: [],
            messages: [],
            games: [],
            drawNumber: 0,
            waitingColor: null
        };
    }

    componentWillUnmount(){
        disconnect(this.userData);
    }
  
    componentDidMount(){
        this.setSubscribers();
    }

    setSubscribers = () => {
        addRoom(users => this.getInitialRoomData(users), users => this.disconnect(users));
        subscribeToNewGameDataAvailable(newData => this.handleNewData(newData));
    }

    getInitialRoomData = (users) => {
        fetch('/api/getInitialRoomData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.userData)
        })
        .then(response => response.json())
        .then(json => this.setInitialRoomData(json, users));
    }

    setInitialRoomData = ({round, squares, messages}, users) => {

        this.setState({ 
            games: round.games, 
            drawNumber: round.drawNumber,
            cost: 0,
            squares,
            messages,
            users
        }, () => {
            chatUtils.handlePositionScroll('card-body');
        });
    }

    getUserColor = () => {
        let {userName} = this.userData;
        return this.state.users.length > 0 ? this.state.users.find(user => user.userName.toLowerCase() === userName.toLowerCase()).color : '';
    }

    disconnect = users =>{
        this.setState({
            users: users
        });
    }

    setSquares = squareProperies => {

        const squares = [...this.state.squares];
      
        let {index} = squareProperies;
        squares[index] = squareProperies;

        this.setState({
            squares
        })
    }
    
    setMessages = (messageProperties) => {

        let {color} = messageProperties;

        this.setState({
            messages: [...this.state.messages, messageProperties],
            waitingColor: color === this.state.waitingColor ? null : this.state.waitingColor
        }, () => {
            chatUtils.checkScrollAction('card-body');
        });

    }

    messageWaiting = (waitingColor, isTyping) => {

        this.setState({
            waitingColor: isTyping ? waitingColor : this.state.waitingColor === waitingColor ? null : this.state.waitingColor
        }, () => {
            chatUtils.checkScrollAction('card-body');
        });
        
    }

    downloadRows = async () => {

        let downloadProperties = {
            ...this.userData,
            drawNumber: this.state.drawNumber
        };
       
        let response = await fetch('/api/download', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(downloadProperties)
        });

        let text = await response.text();

        if(text.length)
        {
            let blob = new Blob([text.replace(/\n/g, "\r\n")], {type: "text/plain;charset=utf-8"});
            saveAs(blob, `${this.userData.gameType}${Date.now()}.txt` );
        }
        else
        {
            this.showNotification("Spela för minst 1 kr eller max 139 968 kr");
        }
            
    }

    renderDownloadButton = () =>
    {
        if(!isMobile)
        {
            return (
                <div className="download-btn">
                    <button onClick={this.downloadRows} className="btn btn-success">Ladda ned</button>
                </div>
            )
        }
       
    }

    handleNewData = newData => {
        if(newData)
            this.showNotification("Ny omgång har påbörjats. Ladda om sidan eller gå tillbaka till registreringen för att skapa ett nytt bolag.");
    }

    showNotification = message => {
        toast.info(message , {
            position: toast.POSITION.TOP_CENTER
        });

        // toast("Custom Style Notification with css class!", {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //     className: 'foo-bar'
        // });
    }

    render(){

        const {gameType} = this.userData;

        if(gameType !== 'stryk' && gameType !== 'euro')
            return <Redirect to='/' />

        const color = this.getUserColor();

        return (
            <div className="app">
            
                {this.renderDownloadButton()}

                <div className="chat-area">
                    <ChatSurface 
                        userData ={this.userData}
                        drawNumber={this.state.drawNumber}
                        color={ color }
                        waitingColor={this.state.waitingColor}
                        messages={this.state.messages}
                        setMessages={this.setMessages}
                        messageWaiting={this.messageWaiting}
                    />
                </div>

                <div className="main-surface">
                    <MainSurface 
                        userData = {this.userData}
                        drawNumber={this.state.drawNumber} 
                        color={ color }
                        squares = {this.state.squares}
                        setSquares={this.setSquares}  
                        games={this.state.games} 
                    />

                    {
                        this.state.squares.length 
                        ? <Cost squares={this.state.squares} />
                        : ""
                        
                    }
                    
                </div>

                <div className="users-area">
                    <UsersSurface users={this.state.users} />
                </div>
            </div>
            
        );

    }
}

export default GameContainer;