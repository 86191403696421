import React, { Component } from 'react';
import { squareChanged, subscribeToSquareChange } from '../socketservices'
import preventDoubleTapZoom from '../helpers'

class MainSurface extends Component {
    constructor(props) {
      super(props);

      this.numberOfSquaresPerRow = 3;

      //this.gamesSurfaceAnimationDelay = this.getAnimationDelays(13, 400);
      this.bettingSurfaceAnimationDelay = this.getAnimationDelays(39, 1000);
      this.createShuffeledArray();

      subscribeToSquareChange(squareProperties => props.setSquares(squareProperties));
    }

    getAnimationDelays(spread, totalDelay){
      return Array(spread)
        .fill(Math.floor(totalDelay/spread))
        .reduce((acc, currentValue, i) => 
        { 
            if(acc.length)
                acc.push(acc[i-1] + currentValue);
            else
                acc.push(currentValue);

            return acc;  
        }, []); 
    }


    yatesShuffle(array){
      let m = array.length, t, i;

      while(m)
      {
          i = Math.floor(Math.random() * --m);

          t = array[m];
          array[m] = array[i];
          array[i] = t;
      }

      return array;
    }

    createShuffeledArray(){
        this.bettingSurfaceAnimationDelay = this.yatesShuffle([...this.bettingSurfaceAnimationDelay])
            .map((item) => item + 'ms');
    }

    correctSign = (i) => {

      switch(i){
        case 0:
          return "1";
        case 1:
          return "X";
        case 2:
          return "2";
        default:
          break;
      }

    }

    renderGameRows(){

      let {room, gameType} = this.props.userData;

      return this.props.games.map( (game, index) => 
        <ul key={game.id} className="game-holder">
          <li className="game-row">
              <div className="game-teams">
                {`${game.id}. ${game.homeTeam.substring(0, 10)} - ${game.awayTeam.substring(0, 10)}`}
              </div>
              
              <ul className="squareHolder">
                <GameRow 
                  increment={index * 3}
                  gameType={gameType}
                  room = {room}
                  squares={this.props.squares}
                  drawNumber={this.props.drawNumber}
                  color={this.props.color}
                  animationDelays={this.bettingSurfaceAnimationDelay}
                  correctSign = {this.correctSign}
                />
              </ul>
          
            
          </li>
        </ul>

      );
    }

  

    render() {
      return (

        <div className="gameContainer">
          {this.renderGameRows()}
        </div>
      
      );
    }
}

//Deal with all props
const GameRow = (props) => (

  Array(3)
    .fill(null)
    .map((item,i) => 
    {
      let index = props.increment + i,
          square = props.squares[index];

      return (
        <li key={index} className="list-sq">
          <button 
            className="sq" 
            style={{backgroundColor: square.color, animationDelay: props.animationDelays[index]}}
            onClick={() => squareChanged(props.drawNumber, props.gameType, props.room, square.color === props.color ? null : props.color, index)}
            onTouchStart={preventDoubleTapZoom}> 
            {props.correctSign(i)}
          </button>
        </li>
        
      )
    }  

     
    )
);


//animationDelay: props.delay


export default MainSurface;