import React from 'react';

const usersSurface = (props) => (
    <div>
        {props.users.map((user,i) => 
            <div key={i} className="user-container">
                <div style={{backgroundColor: user.color}} className="user-color-box"></div>
                {
                    user.isActive 
                    ? <span>{user.userName}</span>
                    : <span style={{textDecoration: 'line-through'}}>{user.userName}</span>

                }
                
            </div>
        )}
    </div>
);

export default usersSurface;