import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import InfoModal from './infoModal';

class Home extends Component {

    constructor(props){
      super(props);
  
      this.state = {
        modalShow: false
      };
    }
  
    modalClose = () => this.setState({ modalShow: false });
  
    render() {
  
      return (
  
        <div className="page-wrapper">
    
          <div className="options-container">
            <div className="info-btn" onClick={() => this.setState({ modalShow: true })}><i>i</i></div>
    
            <div className="beta">
              <span>{`{ Beta v1.0 }`}</span>
            </div>

            <div className="type-game-holder">
                <Link className="game-links" to="/stryk">
                  <figure className="brand bg-stryktipset brand-stryktipset" partial="">
                      <span className="game-name">Stryktipset</span>
                  </figure>
                </Link>

                <Link className="game-links" to="/euro">
                  <figure className="brand bg-europatipset brand-europatipset" partial="">
                      <span className="game-name">Europatipset</span>
                  </figure>
                </Link>
            </div>
          </div>
          

          <footer><a href="mailto:bet2gether.se@gmail.com">bet2gether.se@gmail.com</a></footer>
  
          <InfoModal
            show={this.state.modalShow}
            onHide={this.modalClose}
          />
  
        </div>
      )
    }
  }

  export default Home;