import React, { Component } from 'react';
import { subscribeToMessageSent, sendMessage, sendWaitingMessage, subscribeToWaitingMessage } from '../socketservices';
import uuid from 'uuid/v4';


class chatSurface extends Component {

    constructor(props) {
        super(props);

        this.uniqueId = uuid();

        this.timer = null;
        this.triggerTyping = false;

        subscribeToMessageSent(messageProperties => props.setMessages(messageProperties));
        subscribeToWaitingMessage((color, isTyping) => props.messageWaiting(color, isTyping));
        
        this.state = {
            message: ''
        };
    }

    sendMessage = () => {

        let {room, gameType, userName} = this.props.userData;

        let messageProperties = 
        {
            gameType,
            room,
            sender: userName,
            drawNumber: this.props.drawNumber,
            message: this.state.message,
            color: this.props.color
        };

        sendMessage(messageProperties);
        this.setState({message: ''});
    }

    handleWaitingMessage = isTyping => {

        let {room, gameType} = this.props.userData;

        let messageProperties = {
            room,
            gameType,
            drawNumber: this.props.drawNumber,
            color: this.props.color,
            isTyping
        }

        sendWaitingMessage(messageProperties);

        this.triggerTyping = isTyping;
    }

    onChange = e => {

        clearTimeout(this.timer); 
        this.timer = setTimeout(() => this.handleWaitingMessage(false), 3000);

        if(!this.triggerTyping)
        {
            this.triggerTyping = true;
            this.handleWaitingMessage(true);
        }

        this.setState({
            message : e.target.value
        });
    }

    onKeyUp = e => {

        if (e.key === 'Enter') {
            this.resetAndSend();
        }
    }

    resetAndSend = () => {

        if(this.state.message.length)
        {
            clearTimeout(this.timer);

            this.triggerTyping = false;
            this.sendMessage();
        }
        
    }

    render(){

        let {room} = this.props.userData,
            numberOfMessages = this.props.messages.length;

        let messagesText = numberOfMessages > 1 
                ? `${numberOfMessages} meddelanden` 
                : numberOfMessages > 0 
                ? `${numberOfMessages} meddelande` 
                : `inga meddelanden`;

        return (
            <div className="container-fluid h-100">
                <div className="row justify-content-center h-100">
                    <div className="chat">
                        <div className="card">
                            <div className="card-header msg_head">

                                <div className="d-flex bd-highlight">

                                    <div className="user_info">
                                        <span>Chat för {room}</span>
                                        <p>{messagesText}</p>
                                    </div>

                                </div>
                            </div>

                            <div className="card-body msg_card_body">
                                {
                                    this.props.messages.map((message,i) => {
                                        return (<ChatMessage key={i} message={message} />);
                                    })
                                }
                                {
                                     this.props.waitingColor !== null
                                        ? <WaitForMessage color={this.props.waitingColor}/>
                                        : ""
                                }
                            </div>

                            <div className="card-footer">
                                <div className="input-group">
                                    <textarea value={this.state.message} onChange={this.onChange} onKeyUp={this.onKeyUp} name="" className="form-control type_msg" placeholder="Skriv ditt meddelande..."></textarea>
                                    <button onClick={() => this.resetAndSend()}>Skicka</button>
                                </div>
						    </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const ChatMessage = props => (

    <div className="d-flex justify-content-start mb-4">
        <div style={{ backgroundColor: props.message.color }} className="msg_cotainer">
            {/* <span className="msg_time">{props.message.sender}</span> */}
            <span className="user-name">{props.message.sender}</span>
            <br />
            {props.message.message}
        </div>
    </div>

);

const WaitForMessage = props => (

        <div className="msg_cotainer lds-ellipsis">
            {
                Array(3).fill(null).map((item, i) => (<div key={i} style={{background: props.color}}></div>))
            }
        </div>

);



export default chatSurface;