// todo: wrap in class or ff and export whole thing

//On heroku
import io from "socket.io-client";

//On local
//import openSocket from "socket.io-client";
let socket = null;

const setSocket = userData => {
  //socket = openSocket("http://localhost:5000");
  socket = io();

  socket.on("connect", () => {
    socket.emit("addRoom", userData);
  });
};

const subscribeToSquareChange = cb => {
  socket.on("handleSquareChanged", squareProperies => cb(squareProperies));
};

const subscribeToMessageSent = cb => {
  socket.on("handleMessageSent", messageProperties => cb(messageProperties));
};

const subscribeNewUser = cb => {
  socket.on("handleNewUser", users => cb(users));
};

const subscribeToNewGameDataAvailable = cb => {
  socket.on("handleNewDataAvailable", newData => cb(newData));
};

const subscribeToWaitingMessage = cb => {
  socket.on("handleWaitingMessage", (color, isTyping) => cb(color, isTyping));
};

const squareChanged = (drawNumber, gameType, room, color, index) => {
  socket.emit("squareChanged", { drawNumber, gameType, room, color, index });
};

const sendMessage = messageProperties => {
  socket.emit("messageSent", messageProperties);
};

const sendWaitingMessage = messageProperties => {
  socket.emit("waitingMessage", messageProperties);
};

const addRoom = (cbConnect, cbDisconnect) => {
  socket.on("connected", users => cbConnect(users));
  socket.on("userDisconnected", users => cbDisconnect(users));
};

const disconnect = userData => {
  socket.emit("userDisconnect", userData);
  socket.close();
};

export {
  setSocket,
  subscribeToSquareChange,
  subscribeToNewGameDataAvailable,
  squareChanged,
  subscribeToMessageSent,
  sendMessage,
  subscribeNewUser,
  addRoom,
  disconnect,
  sendWaitingMessage,
  subscribeToWaitingMessage
};
