import React from 'react';
import {Button, Modal} from 'react-bootstrap'

const InfoModal = props => (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <h4><b>Info</b></h4>
          <p>
            Denna sida är till för er som gillar att spela stryktipset eller europatipset tillsammans med vänner.<br /><br />
            <b>Så här fungerar det:</b>
          </p>
          <ol className="info-list">
            <li>Välj att spela antingen stryk eller europatipset.</li>
            <li>Ange ett bolagsnamn samt ett användarnamn.</li>
            <li>Skicka bolagsnamnet till dina vänner. (Går ej att skicka via sidan så detta görs på eget sätt)</li>
            <li>Dina vänner skriver in det bolagsnamn som du just har skapat och ett eget användarnamn.</li>
            <li>Varje användare får en egen färg som ni nu använder för att lägga en stryk eller europatipsrad tillsammans.</li>
            <li>Ni kan självklart chatta med varandra medan ni lägger raden.</li>
            <li>När ni är nöjda med raden laddar ni hem den enkelt till en textfil som sedan kan laddas upp på <a href="https://svenskaspel.se" rel="noopener noreferrer" target="_blank">svenskaspel.se</a> (filen går endast att ladda hem från en dator och gäller bara M-system)</li>
        </ol>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Stäng</Button>
        </Modal.Footer>
      </Modal>
);

export default InfoModal;