
import React, { Component } from 'react'
import {Router, Route, Switch } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory'
import ReactGA from 'react-ga'

import RegisterSurface from './registerSurface';
import GameContainer from './gameContainer';
import Home from './home'

const history = createHistory()
history.listen(location => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
})

class AppRoutes extends Component {
	componentDidMount() {
		ReactGA.pageview(window.location.pathname)
	}

	render() {
		return (
			<Router history={history}>
				<Switch>
                    <Route path='/' exact component={Home} />
                    <Route path='/:gameType' exact component={RegisterSurface} />
                    <Route path='/:gameType/:userName/:company' exact component={GameContainer} />
                    <Route path='/' component={Home} />
                </Switch>
			</Router>
		)
	}
}

export default AppRoutes;
